<template>
  <PegawaiUploadForm mode="Import" module="Pegawai"> </PegawaiUploadForm>
</template>

<script>
import PegawaiUploadForm from './uploadForm';

const PegawaiAdd = {
  name: 'PegawaiAdd',
  components: { PegawaiUploadForm },
};

export default PegawaiAdd;
</script>
